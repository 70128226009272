/*:global(.tooltip) {
    position: relative!important;
    display: inline-block!important;
    padding: 0!important;
}
:global(.tooltip) :global(.tooltip-text) {
    visibility: hidden;
    width: fit-content;
    text-align: center;
    /* padding: 5px 9px; * /
    /* border-radius: 6px; * /

    position: absolute;
    z-index: 1;
    top: +3px;
    left: 105%;
}
:global(.tooltip):hover :global(.tooltip-text) {
    visibility: visible;
}*/

:global(.p-orderlist.p-orderlist-hide-controls) :global(.p-orderlist-controls) {
    width: 0;
    padding: 0;
    visibility: collapse;
}
:global(.p-orderlist-more-height) ul:global(.p-orderlist-list) {
    min-height: unset;
    max-height: unset;
}
:global(.p-orderlist-cursor-default) ul:global(.p-orderlist-list) li:global(.p-orderlist-item) {
    cursor: default!important;
}
:global(.p-orderlist-width-available) :global(.p-orderlist-list-container) {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    /* width: fill-available; */
    width: stretch;
}

:global(.p-inplace) :global(.p-inplace-display.p-disabled) {
    /* opacity: .72; */
    opacity: unset;
}

:global(.p-orderlist-tight) ul:global(.p-orderlist-list) {
    padding: .5rem 0;
}
:global(.p-orderlist-tight) ul:global(.p-orderlist-list) li:global(.p-orderlist-item) {
    padding: .3rem .7rem;
}

:global(.p-inplace-display-revert-style) :global(.p-inplace-display) {
    display: revert!important;
}

:global(.editable-muleapp-name),
:global(.editable-muleapp-latest),
:global(.editable-muleapp-url),
:global(.editable-muleapp-region) {
    white-space: nowrap;
    overflow: hidden;
    margin: 0 .25rem;
}
:global(.editable-muleapp-name) { width: 15%; }
:global(.editable-muleapp-latest) { width: 10%; }
:global(.editable-muleapp-url) { width: 63%; }
:global(.editable-muleapp-region) { width: 12%; }
:global(.editable-edited) {
    font-style: italic!important;
    text-decoration: underline;
}
:global(.editable-muleapp-button) {
    width: 10.5rem;
    margin: 0 .25rem !important;
}

:global(.button-add-muleapp) {
    right: 2rem;
    bottom: 2rem;
    position: fixed;
    z-index: 11;
    background: var(--surface-a);
}
