/* _core.scss */

$linkColor: #2196f3;
$focusBorderColor:#BBDEFB;

html {
    font-size: 14px;
}

body {
    margin: 0px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--surface-a);
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

p {
    line-height: 1.5;
    margin: 0 0 1rem 0;
}

input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1)
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3)
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1)
    }
}

pre[class*="language-"] {
    &:before, &:after {
        display: none !important;
    }

    code {
        border-left: 10px solid var(--surface-d) !important;
        box-shadow: none !important;
        background: var(--surface-e) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;

        .token {
            &.tag,
            &.keyword {
                color: #2196F3 !important;
            }

            &.attr-name,
            &.attr-string {
                color: #2196F3 !important;
            }

            &.attr-value {
                color: #4CAF50 !important;
            }

            &.punctuation {
                color: var(--text-color);
            }

            &.operator,
            &.string {
                background: transparent;
            }
        }
    }
}

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left {
    top: 100px;
}

.action-button {
    font-weight: bold;
    text-align: center;
    color: #ffffff !important;
    background-color: #fe8702;
    padding: 10px 24px 9px 24px;
    border-radius: 3px;
    transition: background-color .2s;

    &:hover {
        background-color: #c56a05;
        color: #ffffff;
    }
}

.liveEditorSplitButton {
    .p-splitbutton-defaultbutton {
        .p-button-label {
            display: none;
        }
    }
}

.liveEditorHelperText {
    font-size: .875rem;
    padding-bottom: .2rem;
    margin-bottom: .25rem;
    color: var(--text-color-secondary);
}

.liveEditorPanel {
    .p-menuitem-link {
        height: 38px;

        img {
            margin-left: -4px;
            width: 20px;
            height: 20px;
        }
    }
}

/* _sidebar.scss */

.layout-sidebar {
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100% - 70px);
    background-color: var(--surface-f);
    width: 250px;
    border-right: 1px solid var(--surface-d);
    user-select: none;
    transition: transform .4s cubic-bezier(.05,.74,.2,.99);
    display: flex;
    flex-direction: column;

    .layout-sidebar-filter {
        padding: 1.25rem 1rem;
        border-bottom: 1px solid var(--surface-d);
        background-color: var(--surface-a);

        .layout-sidebar-filter-content {
            width: 100%;

            .clear-icon {
                cursor: pointer;
                padding: .25rem;
                border-radius: 50%;
                right: .25rem;
                margin-top: -.75rem;
                transition: background-color .2s;

                &:hover {
                    background-color: var(--surface-c);
                }
            }
        }
    }

    .layout-menu {
        padding: 0 1rem;
        overflow-y: auto;
        flex-grow: 1;

        .menu-category {
            display: block;
            color: var(--text-color-secondary);
            font-weight: 600;
            user-select: none;
            padding: 1.5rem 0 1rem 0;
            font-size: 0.857rem;
            text-transform: uppercase;
            border-top: 1px solid var(--surface-d);

            &:first-child {
                border-top: 0 none;
            }
        }

        .menu-items {
            padding: 0 0 1rem 0;
            display: flex;
            flex-direction: column;

            a,
            button {
                color: var(--text-color);
                display: flex;
                padding: .5rem;
                border-radius: 3px;
                cursor: pointer;
                align-items: center;

                .layout-menu-badge {
                    padding: .14rem .5rem;
                    font-size: .7rem;
                    line-height: 1.4;
                }

                &:hover {
                    background-color: var(--surface-c);
                }

                &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: 0 0 0 0.2em $focusBorderColor;
                }

                &.router-link-exact-active {
                    font-weight: 700;
                }
            }

            div {
                &.router-link-exact-active {
                    > a {
                        font-weight: 700;
                    }

                    ul {
                        display: block;
                    }
                }

                ul {
                    padding: .5rem 0;
                    margin: 0;
                    list-style-type: none;

                    a {
                        font-size: .875rem;
                        padding: .475rem .5rem .475rem 2rem;
                    }
                }
            }
        }
    }
}

/* _topbar.scss */

.layout-topbar {
    background-color: var(--surface-f);
    padding: 0;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 997;
    box-shadow: 0 0 4px rgba(0,0,0,0.25);
    border-bottom: 1px solid var(--surface-d);
    display: flex;
    align-items: center;
    padding: 0 35px;

    .menu-button {
        display: none;
        color: var(--text-color);
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        transition: background-color .2s;
        cursor: pointer;

        &:hover {
            background-color: var(--surface-c);
        }

        i {
            font-size: 24px;
            line-height: inherit;
        }
    }

    .logo {
        img {
            width: 180px;
        }

        &:focus {
            outline: 0 none;
            transition: box-shadow .2s;
            box-shadow: 0 0 0 0.2rem $focusBorderColor;
        }
    }

    .app-theme {
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        padding: .5rem;
        border-radius: 4px;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        width: 39px;
        height: 39px;
        margin-left: 70px;

        img {
            width: 25px;
        }
    }

    .topbar-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 100%;
        margin-left: auto;
        display: flex;

        > li {
            height: 70px;
            line-height: 70px;

            > a,
            > .p-link {
                text-decoration: none;
                color: var(--text-color);
                min-width: 120px;
                font-size: 16px;
                display: block;
                text-align: center;
                user-select: none;
                line-height: 68px;
                border-bottom: 2px solid transparent;
                border-radius: 0;
                transition: border-bottom-color .2s;
                cursor: pointer;

                &:hover, &:focus {
                    border-bottom-color: var(--primary-color);
                }

                &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: inset 0 0 0 0.2em $focusBorderColor;
                }
            }

            &.topbar-submenu {
                position: relative;

                > ul {
                    position: absolute;
                    transform-origin: top;
                    top: 70px;
                    right: 0;
                    width: 275px;
                    max-height: 400px;
                    background-color: var(--surface-f);
                    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
                    overflow: auto;
                    list-style-type: none;
                    padding: 1rem;
                    margin: 0;
                    border-radius: 3px;

                    > li {
                        line-height: 1;

                        &.topbar-submenu-header {
                            display: block;
                            color: var(--text-color-secondary);
                            font-weight: 600;
                            user-select: none;
                            padding: 1.5rem 0 1rem 0;
                            font-size: 0.857rem;
                            text-transform: uppercase;

                            &:first-child {
                                padding-top: 1rem;
                            }
                        }
                    }

                    a,
                    .p-link {
                        text-decoration: none;
                        color: var(--text-color);
                        padding: .5rem;
                        display: flex;
                        align-items: center;
                        user-select: none;
                        border-radius: 3px;
                        cursor: pointer;
                        width: 100%;

                        &:hover {
                            background-color: var(--surface-c);
                        }

                        span {
                            margin-left: .5rem;
                        }

                        i {
                            font-size: 18px;
                            color: var(--text-color-secondary);
                        }

                        img {
                            width: 32px;
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }

        .theme-badge {
            padding: 2px 4px;
            vertical-align: middle;
            border-radius: 3px;
            font-weight: bold;
            font-size: 11px;
            position: relative;
            top: -1px;
            line-height: 1;
        }

        .theme-badge.material {
            color: #ffffff;
            background: linear-gradient(to bottom, #2196F3, #2196F3);
        }

        .theme-badge.bootstrap {
            color: #ffffff;
            background: linear-gradient(to bottom, #563D7C, #966BD8);
        }

        .theme-badge.darkmode {
            color: #ffffff;
            background: linear-gradient(to bottom, #141d26, #5a6067);
        }
    }
}

/* _content.scss */

.layout-content {
    margin-left: 250px;
    padding-top: 70px;

    .content-section {
        padding: 2rem;

        &.introduction {
            background-color: var(--surface-b);
            color: var(--text-color);
            padding-bottom: 0;
            display: flex;
            align-items: top;
            justify-content: space-between;

            .feature-intro {
                h1 {
                    span {
                        font-weight: 400;
                        color: var(--text-color-secondary);
                    }
                }

                p {
                    margin: 0;
                }

                a {
                    text-decoration: none;
                    color: $linkColor;
                    font-weight: 600;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &.implementation {
            background-color: var(--surface-b);
            color: var(--text-color);

            +.documentation {
                padding-top: 0;
            }
        }

        &.documentation {
            background-color: var(--surface-b);
            color: var(--text-color);

            li {
                line-height: 1.5;
            }

            a {
                text-decoration: none;
                color: $linkColor;
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }

            .doc-tablewrapper {
                margin: 1rem 0;
                overflow: auto;
            }

            i:not([class~="pi"]) {
                background-color: var(--surface-a);
                color: #2196f3;
                font-family: Monaco, courier, monospace;
                font-style: normal;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 4px;
                letter-spacing: .5px;
                border-radius: 3px;
                font-weight: 600;
                margin: 0 2px;
            }

            .p-tabview {
                background: transparent;
                border: 0 none;

                .p-tabview-nav {
                    border-radius: 0;
                    padding: 0;
                    border-bottom: 1px solid var(--surface-d);
                    background-color: transparent;

                    li {
                        margin-right: 0;
                        border: 0 none;
                        top: 1px;
                        line-height: 1;

                        a, a:visited {
                            color: var(--text-color-secondary);
                            text-shadow: none;
                            height: inherit;
                            background-color: transparent;
                            border: 0 none;
                            border-bottom: 1px solid transparent;
                            margin-bottom: -1px;
                            transition: border-bottom-color .2s;

                            &:focus {
                                outline: 0 none;
                                transition: background-color .2s, box-shadow .2s;
                                box-shadow: 0 0 0 0.2em $focusBorderColor;
                            }

                            &:hover {
                                background: transparent;
                                text-decoration: none;
                            }
                        }

                        &.p-highlight a,
                        &.p-highlight:hover a {
                            background: transparent;
                            color: var(--primary-color);
                            border-bottom: 1px solid $linkColor;
                        }

                        &:not(.p-highlight):not(.p-disabled):hover a {
                            color: var(--text-color);
                            border-bottom: 1px solid var(--primary-color);
                        }
                    }

                    .p-tabview-ink-bar {
                        display: none;
                    }
                }

                .p-tabview-panels {
                    background: transparent;
                    border: 0 none;
                    padding: 2rem 1rem;

                    .btn-viewsource {
                        display: inline-block;
                        padding: .5rem 1rem;
                    }

                    a {
                        text-decoration: none;
                        color: $linkColor;
                        font-weight: 600;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .doc-table {
            border-collapse: collapse;
            width: 100%;
            background-color: var(--surface-a);

            th {
                border-bottom: 1px solid var(--surface-d);
                padding: 1rem;
                text-align: left;
            }

            tbody{
                td {
                    padding: 1rem;
                    border-bottom: 1px solid var(--surface-d);
                }
            }
        }
    }

    .card {
        background: var(--surface-e);
        padding: 2rem;
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
        border-radius: 4px;
        margin-bottom: 2rem;

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

/* _responsive.scss */

@media screen and (max-width: 960px) {
    .layout-wrapper.layout-news-active {
        .layout-content {
            padding-top: 180px;
        }

        .layout-sidebar {
            top: 0;
            height: 100%;
        }

        .layout-news-button {
            opacity: 0;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            margin: 0;
            top: 0;
            left: 0;
        }
    }

    .layout-topbar {
        height: 110px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;

        .menu-button {
            display: block;
        }

        .logo {
            img{
                width: 150px;
            }
        }

        .app-theme {
            margin-left: 0;
            margin-right: 23px;
        }

        .topbar-menu {
            background-color: var(--surface-a);
            width: 100%;
            height: 40px;
            margin: 0;
            border-top: 1px solid var(--surface-d);

            > li {
                height: 40px;
                line-height: 40px;
                width: 25%;

                > a,
                > .p-link {
                    padding-bottom: 0;
                    height: 40px;
                    line-height: 38px;
                    width: 100%;
                    font-size: 14px;
                    min-width: auto;
                }

                &.topbar-submenu > ul {
                    top: 40px;
                }
            }
        }
    }

    .layout-sidebar {
        top: 0;
        z-index: 999;
        height: 100%;
        transform: translateX(-100%);

        &.active {
            transform: translateX(0);
        }
    }

    .layout-content {
        margin-left: 0;
        padding-top: 110px;

        .content-section {
            &.introduction {
                flex-direction: column;

                .app-inputstyleswitch {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .layout-mask {
        background-color: rgba(0, 0, 0, 0.1);

        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color .5s;
        }
    }

    .home {
        .introduction > div {
            width: 100%;
        }

        .features > div {
            width: 100%;
        }

        .whouses > div {
            width: 100%;
        }

        .prosupport > div {
            width: 100%;
        }
    }

    .layout-config {
        .layout-config-button {
            left: auto;
            right: -52px;
        }

        &.layout-config-active {
            width: 100%;
        }
    }

    .blocked-scroll {
        overflow: hidden;
    }
}

@media screen and (max-width: 640px) {
    .layout-wrapper.layout-news-active {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    > ul  {
                        top: 180px;
                    }
                }
            }
        }
    }

    .layout-topbar {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    position: static;

                    > ul {
                        top: 110px;
                        position: fixed;
                        right: auto;
                        left: 0;
                        width: 100vw;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .layout-news .layout-news-container img.layouts-news-mockup-image {
        width: 95%;
    }
}
