:global(.jsob-root) {
    margin-right: 5rem;
}
:global(.jsob-root), :global(.jsob-val), :global(.jsob-txt) {
    width: 16rem;
}

:global(.jsob-val) {
    position: relative;
    padding: .5rem 1rem .5rem 1.5rem; /* top right bottom left */
    margin-top: .5rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
:global(.jsob-val):hover {
    box-shadow: 0 0 6px rgb(35 173 255);
    /*outline: auto;*/
    /*outline-color: black;*/
}

:global(.jsob-key) {
    /* font-size: large; */
    font-weight: bold;
    font-family: monospace, "Courier New";
}

:global(.jsob-txt) {
    width: 13rem;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-style: italic;
}

:global(.jsob-bracket-op), :global(.jsob-bracket-ed), :global(.jsob-colon) {
    position: absolute;

    color: var(--surface-b);
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.5rem;
    font-weight: bolder;

    padding: .25rem /*.5rem*/;
    border-radius: 50%;

    display: inline-block;
    text-align: center;

}
:global(.jsob-bracket-op) {
    top: 0;
    /* right: 0; */
    left: 0;
}
:global(.jsob-bracket-ed) {
    bottom: 0;
    left: 0;
}
:global(.jsob-colon) {
    top: 0;
    left: -.25rem;
}

:global(.p-accordion-header-text) {
    width: 100%;
}
:global(.p-divider):global(.p-divider-horizontal):before {
    border-top-style: solid!important;
}