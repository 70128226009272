:global(.datatable-responsive) :global(.p-datatable-responsive) :global(.p-datatable-tbody) > tr > td :global(.p-column-title) {
    display: none;
}

@media screen and (max-width: 40em) {
    :global(.datatable-responsive) :global(.p-datatable):global(.p-datatable-responsive) :global(.p-datatable-thead) > tr > th,
    :global(.datatable-responsive) :global(.p-datatable):global(.p-datatable-responsive) :global(.p-datatable-tfoot) > tr > td {
        display: none !important;
    }

    :global(.datatable-responsive) :global(.p-datatable):global(.p-datatable-responsive) :global(.p-datatable-tbody) > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    :global(.datatable-responsive) :global(.p-datatable):global(.p-datatable-responsive) :global(.p-datatable-tbody) > tr > td :global(.p-column-title) {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    :global(.datatable-responsive) :global(.p-datatable):global(.p-datatable-responsive) :global(.p-datatable-tbody) > tr > td :global(.p-column-content) {
        display: inline !important;
        margin-left: auto;
    }

    :global(.datatable-responsive) :global(.p-datatable):global(.p-datatable-responsive) :global(.p-datatable-tbody) > tr > td:first-child {
        border-bottom: 1px solid var(--surface-d);
        border-top: 2px solid var(--surface-d);
    }
}

:global(.p-datatable.p-datatable-striped.p-datatable-stripped-better) :global(.p-datatable-tbody) > :global(tr) {
    background: var(--surface-200);
}

:global(.p-datatable.p-datatable-striped.p-datatable-stripped-better) :global(.p-datatable-tbody) > :global(tr.p-row-odd) {
    background: var(--surface-a);
}

:global(.p-datatable.p-datatable-hover-background) :global(.p-datatable-tbody) > :global(tr):hover {
    background: var(--surface-300) !important;
}

:global(.logspanel-wrapper) {
    position: sticky;
    margin-top: 2rem;
    bottom: 0;
}

:global(.logspanel-panel) {
    height: 15rem;
    background: #222;
}

:global(.logspanel-control) {
    position: relative;
    padding: .5rem;
    z-index: 2;
    background: var(--surface-a);
    top: 0;
    width: fit-content;
    margin-left: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

:global(.logspanel-control) button {
    height: 2rem!important;
    width: 2rem!important;
}
