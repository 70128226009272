:global(.tooltip) {
    position: relative!important;
    display: inline-block!important;
    padding: 0!important;
}
:global(.tooltip) :global(.tooltip-text) {
    visibility: hidden;
    width: fit-content;
    text-align: center;
    /* padding: 5px 9px; */
    /* border-radius: 6px; */

    position: absolute;
    z-index: 1;
    top: +3px;
    left: 105%;
}
:global(.tooltip):hover :global(.tooltip-text) {
    visibility: visible;
}

:global(.p-orderlist.p-orderlist-hide-controls) :global(.p-orderlist-controls) {
    width: 0;
    padding: 0;
    visibility: collapse;
}

:global(.p-orderlist-more-height) ul:global(.p-orderlist-list) {
    min-height: unset;
    max-height: unset;
}

:global(.searchbar-by-type), :global(.searchbar-by-args) {
    height: 36.5938px;
    width: 10rem;
}
:global(.searchbar-by-type) > span {
    padding: .7rem;
}
